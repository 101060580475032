<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="w-100">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>
                <v-icon left>animation</v-icon>
                Clone {{ application.long_id }}
            </v-card-title>
            <v-card-text class="pt-3">
                Do you want to make a clone of your application in this instance, with all the installed libraries copied to the new application? The operation
                will run in the background and can take a while depending on the size of the application's library.
            </v-card-text>
            <v-card-text v-if="error">
                <v-alert color="error" text icon="warning" class="mb-0">Something went wrong during cloning. please try again later.</v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="dialog = false" :disabled="loading">Cancel</v-btn>
                <v-btn v-if="success" color="success" text>
                    <v-icon>check</v-icon>
                </v-btn>
                <v-btn v-else color="primary" text @click="clone" :loading="loading">Clone</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AppCloneBtn',
    data() {
        return {
            dialog: false,
            loading: false,
            success: false,
            error: null
        }
    },
    props: {
        application: { type: Object, required: true }
    },
    methods: {
        async clone() {
            this.loading = true
            try {
                await this.$axios.post(`/applications/${this.application.aid}/clone`)
                this.success = true
                setTimeout(() => {
                    this.dialog = false
                    this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
                }, 2000)
            } catch (error) {
                this.error = error
            }
            this.loading = false
        }
    }
}
</script>
